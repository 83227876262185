import React from 'react';
import { showErrorAlert } from '../../../../../../../alerts';
import { useSnackbar } from 'notistack';
import { getIntegrationEntities } from '../../../../../../api';

export default function useSetups(integrationId, integrationSetups) {
  const { enqueueSnackbar } = useSnackbar();
  const [setups, setSetups] = React.useState([]);

  React.useEffect(() => {
    if (integrationId) {
      getIntegrationEntities(integrationId)
        .then(({ data }) => {
          setSetups(
            data
              .map((entity) => {
                const savedIntegrationSetup = integrationSetups.find((setup) =>
                  setup.integration_entity?.id === entity.id
                );

                if (savedIntegrationSetup) {
                  return savedIntegrationSetup;
                } else {
                  return {
                    integration_entity: entity,
                    integration_mappings: entity.integration_fields
                      .map((field) => ({
                        reference: '',
                        integration_field: field,
                      })),
                  };
                }
              }),
          );
        })
        .catch(() => {
          showErrorAlert(enqueueSnackbar);
        });
    }
  }, [integrationId]);

  return [setups, setSetups];
}
