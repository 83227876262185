import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import SimpleSelect from '../../../../../select';
import { Fieldset, Label } from '../../../../../globals';
import MappingsList from './components/MappingsList';
import HiddenFields from './components/HiddenFields';
import { buildInitialConditions } from '../../utils';
import ScheduleActions from '../ScheduleActions';
import TimeSection from '../TimeSection';
import ConditionalActionSection from '../ConditionalActionSection';
import {
  createIntegrationRefreshes,
  getIntegrationEntities,
} from '../../../../../api';
import { nanoid } from 'nanoid';
import { useTranslation } from '../../../../../../i18n';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import SyncIcon from '@mui/icons-material/Sync';
import { usePulling } from '../../../../../hooks';
import { useSnackbar } from 'notistack';
import { showErrorAlert } from '../../../../../../alerts';
import useSetups from './hooks/useSetups';

export const optionLabel = (text, archived) => {
  return archived ? <s>{text}</s> : text;
};

export default function HubspotActionForm({
  errors,
  serializedErrors,
  experienceId,
  workspaceId,
  hubspotIntegrationId,
  attributes,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'HubspotActionForm' });
  const { enqueueSnackbar } = useSnackbar();
  const { integrationSetups } = attributes;
  const [setups, setSetups] = useSetups(hubspotIntegrationId, integrationSetups);
  const [currentSetup, setCurrentSetup] = React.useState();
  const [refreshId, setRefreshId] = React.useState();
  const [isPulling, setIsPulling] = React.useState(false);
  const showActiveSetupRef = React.useRef(true);

  React.useEffect(() => {
    const activeSetup = setups.find((s) => s.active);
    const changeEntity = (setup) => {
      const { id, hubspot_name, hubspot_archived } = setup.integration_entity;
      handleChangeEntity({
        label: optionLabel(hubspot_name, hubspot_archived),
        value: id,
      });
    };

    if (activeSetup && showActiveSetupRef.current) {
      changeEntity(activeSetup);
    } else if (currentSetup) {
      changeEntity(currentSetup);
    }
  }, [setups, integrationSetups]);

  const entitiesSelectOptions = React.useMemo(() => {
    return setups.map(({ integration_entity }) => ({
      label: optionLabel(integration_entity.hubspot_name, integration_entity.hubspot_archived),
      value: integration_entity.id,
    }));
  }, [setups]);

  const handleChangeEntity = ({ value: entityId }) => {
    setCurrentSetup(
      setups.find(({ integration_entity }) =>
        integration_entity.id === entityId
      ),
    );
  };

  const handleSelectField = (
    { value: selectedId, label: selectedHubspotLabel },
    index,
  ) => {
    // Checking if current setup already has selected mapping but it has _destroy attribute. We'll need to do some reorder next
    let currentMappingWithDestroyIndex;
    const currentMappingWithDestroy = currentSetup.integration_mappings.find(
      ({ integration_field }, index) => {
        if (integration_field.id === selectedId) {
          currentMappingWithDestroyIndex = index;
          return true;
        }
      },
    );

    if (currentMappingWithDestroy) {
      setCurrentSetup((prev) => {
        let mappingToReorder;
        const newMappings = prev.integration_mappings
          .map((mapping, i) => {
            if (i === index) {
              mappingToReorder = { ...mapping, _destroy: true };
              return { ...currentMappingWithDestroy, _destroy: false };
            } else {
              return mapping;
            }
          })
          .map((m, i) =>
            i === currentMappingWithDestroyIndex ? mappingToReorder : m
          );

        return {
          ...prev,
          integration_mappings: newMappings,
        };
      });
    } else {
      setCurrentSetup((prev) => {
        return {
          ...prev,
          integration_mappings: prev.integration_mappings
            .map((mapping, i) => {
              if (i === index) {
                return {
                  ...mapping,
                  integration_field: {
                    id: selectedId,
                    hubspot_label: selectedHubspotLabel,
                  },
                };
              } else {
                return mapping;
              }
            }),
        };
      });
    }
  };

  const handleChangeReference = (value, index) => {
    setCurrentSetup((prev) => {
      return {
        ...prev,
        integration_mappings: prev.integration_mappings.map((field, i) => {
          if (i === index) {
            return { ...field, reference: value };
          } else {
            return field;
          }
        }),
      };
    });
  };

  const handleDeleteField = (mappingId, fieldId, index) => {
    setCurrentSetup((prevSetup) => {
      return {
        ...prevSetup,
        integration_mappings: (prevSetup.id && mappingId && fieldId)
          ? prevSetup.integration_mappings.map((field, i) => {
            if (i === index) {
              return { ...field, _destroy: true };
            } else {
              return field;
            }
          })
          : prevSetup.integration_mappings.filter((_field, i) => i !== index),
      };
    });
  };

  const handleAddField = () => {
    setCurrentSetup((prev) => {
      return {
        ...prev,
        integration_mappings: [...prev.integration_mappings, {
          reference: '',
          integration_field: { nanoid: nanoid() },
        }],
      };
    });
  };

  // The next array is used for the hidden inputs and rendering errors
  const currentIntegrationSetups = React.useMemo(() => {
    if (!currentSetup) {
      return [];
    }

    if (_isEmpty(integrationSetups)) {
      return [currentSetup];
    }

    const setupsWithId = integrationSetups.filter((s) => s.id);
    // Replace selected setup with the one from the attributes or add it to the end of the list
    const foundCurrentSetup = setupsWithId.find((s) =>
      s.id === currentSetup?.id
    );

    if (foundCurrentSetup) {
      return setupsWithId.map((s) =>
        s.id === foundCurrentSetup.id ? currentSetup : s
      );
    } else {
      return [...setupsWithId, currentSetup];
    }
  }, [currentSetup, integrationSetups]);

  const setupErrorsIndex = React.useMemo(() => {
    if (!currentSetup || _isEmpty(serializedErrors?.integration_setups)) {
      return;
    }

    let index;

    integrationSetups.forEach((s, i) => {
      if (s.id === currentSetup.id) {
        index = i;
      }
    });

    return index;
  }, [serializedErrors, integrationSetups, currentSetup]);

  const callback = ({ data }) => {
    if (data.status === 'completed') {
      setIsPulling(false);
      enqueueSnackbar('The form data has been updated.', {
        variant: 'success',
      });
      getIntegrationEntities(hubspotIntegrationId)
        .then(({ data }) => {
          setSetups((prevSetups) => {
            return data
              .map((entity) => {
                const setup = currentSetup?.integration_entity.id === entity.id ? currentSetup : prevSetups.find(s => s.integration_entity.id === entity.id);
                const integrationFields = entity.integration_fields;

                if (setup) {
                  return {
                    ...setup,
                    integration_entity: entity,
                    integration_mappings: setup.integration_mappings.map(m => ({ ...m, integration_field: integrationFields.find(f => f.id === m.integration_field.id) })),
                  };
                } else {
                  return {
                    integration_entity: entity,
                    integration_mappings: integrationFields
                      .map((field) => ({
                        reference: '',
                        integration_field: field,
                      })),
                  };
                }
              });
          });
        })
        .catch(() => {
          showErrorAlert(enqueueSnackbar);
        });
      showActiveSetupRef.current = false;
    }
  };

  const onError = (error) => {
    const errorObj = JSON.parse(error.message)?.errors[0] || {};
    const errorMessage = errorObj.title || errorObj.detail ||
      'Something went wrong. Try again later.';

    setIsPulling(false);
    enqueueSnackbar(errorMessage, { variant: 'error' });
  };

  usePulling({
    url: `/api/v1/integrations/${hubspotIntegrationId}/integration_refreshes/${refreshId}`,
    headers: { 'Workspace-Id': workspaceId },
    timeout: 500,
    isPulling,
    callback,
    onError,
  });

  const handleRefresh = () => {
    createIntegrationRefreshes(hubspotIntegrationId)
      .then(({ data }) => {
        setRefreshId(data.id);
        setIsPulling(true);
      })
      .catch(() => {
        showErrorAlert(enqueueSnackbar);
      });
  };

  return (
    <>
      <Fieldset>
        <Label>Hubspot form</Label>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            py: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <SimpleSelect
              options={entitiesSelectOptions}
              value={currentSetup && {
                label: optionLabel(currentSetup.integration_entity.hubspot_name, currentSetup.integration_entity.hubspot_archived),
                value: currentSetup.integration_entity.id,
              }}
              error={serializedErrors?.integration_setups &&
                serializedErrors.integration_setups['0'] &&
                serializedErrors.integration_setups['0'].integration_entity}
              classNamePrefix="react-select__actions__entity"
              placeholder="Select one..."
              noMargin
              onChange={handleChangeEntity}
            />
          </Box>
          {hubspotIntegrationId && (
            <LoadingButton
              sx={{
                width: 'auto',
                height: 44,
                bgcolor: 'var(--primary-color)',
                color: '#fff',
                borderRadius: '6px',
                textTransform: 'none',
                ml: 'auto',
                '&:hover': {
                  color: '#fff',
                  bgcolor: 'var(--primary-color-darken)',
                },
              }}
              variant="contained"
              startIcon={<SyncIcon />}
              disableElevation
              disableRipple
              onClick={handleRefresh}
              loading={isPulling}
            >
              Refresh
            </LoadingButton>
          )}
        </Box>
      </Fieldset>
      <Fieldset>
        <Label>{t('mapFieldsLabel')}</Label>
        <MappingsList
          experienceId={experienceId}
          mappings={currentSetup?.integration_mappings || []}
          integrationFields={currentSetup?.integration_entity.integration_fields || []}
          currentSetup={currentSetup}
          errors={serializedErrors && serializedErrors.integration_setups &&
            serializedErrors.integration_setups[setupErrorsIndex]}
          onAdd={handleAddField}
          onDelete={handleDeleteField}
          onSelect={handleSelectField}
          onChange={handleChangeReference}
        />
        <HiddenFields
          integrationSetups={currentIntegrationSetups}
          currentSetup={currentSetup}
        />
      </Fieldset>
      <ConditionalActionSection
        initialConditions={buildInitialConditions(attributes.conditions)}
        experienceId={experienceId}
        errors={serializedErrors}
      />
      <ScheduleActions
        attributes={attributes}
        errors={errors}
      />
      <TimeSection specificTime={attributes.specificTime} />
    </>
  );
}
