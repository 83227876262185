import React from 'react';
import SimpleSelect from '../../../../../../../select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useBuildMentionsSteps from '../../../../../../../hooks/useBuildMentionsSteps';
import { EditableMentionsInput } from '../../../../../../../SlateElements';
import { SmallDeleteButton } from '../../../../../../../Button';
import Tooltip from '../../../../../../../tooltip';
import _differenceBy from 'lodash/differenceBy';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { optionLabel } from '../../index.js';

export default function MappingsList(
  {
    experienceId,
    mappings,
    errors,
    integrationFields,
    currentSetup,
    onSelect,
    onDelete,
    onAdd,
    onChange,
  },
) {
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'MentionsInput',
  });

  const addButtonIsDisabled = React.useMemo(() => {
    return integrationFields.length ===
      mappings.filter((mapping) => !mapping._destroy).length;
  }, [integrationFields, mappings]);

  const selectOptions = React.useMemo(() => {
    const destroyedMappings = _differenceBy(
      integrationFields,
      mappings.map((m) => m.integration_field),
      'id',
    ).map(({ id, hubspot_label, hubspot_archived }) => ({ label: optionLabel(hubspot_label, hubspot_archived), value: id }));

    return mappings
      .filter((m) => m._destroy && m.integration_field?.hubspot_label)
      .map(({ integration_field }) => ({
        label: optionLabel(integration_field.hubspot_label, integration_field.hubspot_archived),
        value: integration_field.id,
      }))
      .concat(destroyedMappings);
  }, [integrationFields, mappings]);

  return (
    <>
      <Stack gap={1}>
        {mappings.map(
          ({ id, reference, integration_field, _destroy }, index) => {
            const key = integration_field.id || integration_field.nanoid;
            if (_destroy) {
              return <Box sx={{ position: 'absolute' }} key={key} />;
            } else {
              return (
                <Box
                  key={key}
                  sx={{ display: 'grid', gridTemplateColumns: 'minmax(100px, 200px) 20px minmax(150px, 1fr) 20px', alignItems: 'center', gap: '6px' }}
                  aria-label='hubspot mapping'
                >
                  <SimpleSelect
                    options={selectOptions}
                    value={{
                      label: optionLabel(integration_field.hubspot_label, integration_field.hubspot_archived),
                      value: integration_field.id,
                    }}
                    isSearchable={false}
                    classNamePrefix="react-select__actions__integration_field"
                    onChange={(obj) => onSelect(obj, index)}
                    noMargin
                  />
                  <ArrowBackIcon
                    sx={{
                      fontSize: 18,
                      color: 'var(--font-color-primary--light)',
                    }}
                  />
                  <EditableMentionsInput
                    placeholder='Press "[" to add a reference'
                    initialValue={reference}
                    experienceId={experienceId}
                    steps={mentionSteps}
                    error={errors && errors.integration_mappings && errors.integration_mappings[index]?.reference}
                    inputStyles={{ maxWidth: '534px' }}
                    onValueChange={(value) => onChange(value, index)}
                    noTrailingSpace
                    noAutocomplete
                  />
                  <SmallDeleteButton
                    onClick={() => onDelete(id, integration_field.id, index)}
                    noMargin
                  >
                    –
                  </SmallDeleteButton>
                </Box>
              );
            }
          },
        )}
      </Stack>
      {currentSetup
        ? (
          <Box sx={{ mt: 1 }}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              disableHoverListener={!addButtonIsDisabled}
              title="You've reached the maximum amount of mappings."
              placement="bottom"
              width={280}
            >
              <span>
                <Button
                  sx={{
                    width: 'auto',
                    color: 'var(--primary-color)',
                    textTransform: 'none',
                    borderColor: 'rgba(255, 66, 0, 0.5)',
                    borderRadius: '8px',
                    '&:hover': {
                      color: 'var(--primary-color)',
                      borderColor: 'var(--primary-color)',
                      backgroundColor: 'rgba(255, 66, 0, 0.04)',
                    },
                    '&:disabled:hover': {
                      color: 'rgba(0, 0, 0, 0.26)',
                      borderColor: 'rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'transparent',
                    },
                  }}
                  variant="outlined"
                  onClick={onAdd}
                  disabled={addButtonIsDisabled}
                  disableRipple
                >
                  + Add
                </Button>
              </span>
            </Tooltip>
          </Box>
        )
        : (
          <Card
            sx={{
              p: 2,
              textAlign: 'center',
              backgroundColor: '#FAFAFA',
              borderColor: '#FAFAFA',
            }}
            variant="outlined"
          >
            <Typography variant="subtitle1">This list is empty</Typography>
            <Typography
              sx={{ color: 'rgba(51, 51, 51, 0.6)' }}
              variant="subtitle2"
            >
              Select a HubSpot form first
            </Typography>
          </Card>
        )}
    </>
  );
}
